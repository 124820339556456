import React from 'react'
import { Box, Image, AspectRatio, SimpleGrid } from '@chakra-ui/react'

const List = ({ collection }) => {
	const items =
		collection &&
		collection.map((el, i) => {
			return (
				<Box key={i} minH="75px" minW="116px" boxShadow="base" borderRadius="md">
					<AspectRatio ratio={116 / 75} borderRadius="md">
						<Image src={el.images[0]?.src} borderRadius="md" alt={el.name} />
					</AspectRatio>
				</Box>
			)
		})

	return (
		<SimpleGrid columns={[2, 4]} spacing="1rem" w="full">
			{items}
		</SimpleGrid>
	)
}

export default List
