import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HStack, Container, Box, Heading, Text, Select, Spinner } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import NavBar from '../../atoms/NavBar'
import BackButton from '../../atoms/nav-controls/BackButton'
import Card from '../../atoms/Card'
import FooterBase from '../../molecules/FooterBase'
import config from '../../../../../client/config.client'
import countries from './data'
import List from './List'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

const Controls = () => {
	const navigate = useNavigate()

	return (
		<NavBar>
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(-1)} />
			</HStack>
		</NavBar>
	)
}

const GiftCollection = () => {
	const dispatch = useDispatch()

	const [country, setCountry] = useState('US')
	const [isFetching, setIsFetching] = useState(true)
	const [data, setData] = useState(null)

	useEffect(() => {
		instance
			.get(`/v2/tremendous/products?country=${country}`)
			.then(function (response) {
				// handle success
				console.log('tremendous', response)
				setIsFetching(false)
				setData(response.data.data)
			})
			.catch(function (error) {
				// handle error
				console.log(error)
				setIsFetching(false)
			})
	}, [country])

	const handleChangeCountry = (e) => {
		setCountry(e.target.value)
		setIsFetching(true)
	}

	return (
		<>
			<Controls />

			<Container maxW="container.md" py="1rem" variant="main">
				<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Gift Cards
					</Heading>

					<Text>You can preview below gift cards that are available depending on a given country.</Text>
				</Box>

				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					<Select
						mb="2rem"
						id="cashCollectionCountry"
						placeholder="Choose the country for contributions"
						onChange={handleChangeCountry}
						defaultValue="US"
						value={country}>
						{countries.map((el, i) => (
							<option key={i} value={el.value}>
								{el.label}
							</option>
						))}
					</Select>

					{isFetching && (
						<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="sm" />
					)}
					{!isFetching && <List collection={data} />}
				</Card>
			</Container>
			<FooterBase />
		</>
	)
}

export default GiftCollection
